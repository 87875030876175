var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Товары")]),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","color":"success","disabled":_vm.disabled},on:{"click":_vm.addLineWithNewItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Добавить товар")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","color":"primary","disabled":_vm.disabled},on:{"click":_vm.addLine}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-search-outline")])],1)]}}])},[_c('span',[_vm._v("Добавить товар из списка")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.order.lines,"footer-props":_vm.table.footerProps},scopedSlots:_vm._u([{key:"item.item.image",fn:function(ref){
var item = ref.item;
return [(item.item!=null)?_c('v-img',{staticClass:"rounded my-1",attrs:{"src":_vm._f("imageUrl")(item.item.image,'thumb', true),"height":"120","width":"120"}}):_c('div',{staticClass:"d-flex justify-space-around",staticStyle:{"width":"120px","height":"120px"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-ghost-outline")])],1)]}},{key:"item.item.code",fn:function(ref){
var item = ref.item;
return [(item.item!=null)?_c('div',[_vm._v(" "+_vm._s(item.item.code)+" ")]):_vm._e()]}},{key:"item.item.name",fn:function(ref){
var item = ref.item;
return [(item.item!=null)?_c('div',[_c('div',[_c('router-link',{attrs:{"to":{ name:'order-line.edit-item',params: {itemId:item.item.id, orderId:_vm.order.id} }}},[_vm._v(" "+_vm._s(item.item.name)+" ")])],1),_c('div',{staticClass:"mt-1"},_vm._l((item.item.tags),function(tag,j){return _c('v-chip',{key:j,staticClass:"mr-1 mb-2",attrs:{"small":""}},[_vm._v(_vm._s(tag.name))])}),1),_c('div',[_vm._v(" Длина: "+_vm._s(_vm._f("toint")(item.item.length))+", Ширина: "+_vm._s(_vm._f("toint")(item.item.width))+", Высота: "+_vm._s(_vm._f("toint")(item.item.height))+" ")])]):_c('div',[_vm._v("Товар был удален")])]}},{key:"item.lineAmount",fn:function(ref){
var item = ref.item;
return [(item.amountDiscount>0)?_c('div',[_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.amount))]),_c('br'),_c('span',{staticClass:"red--text text--lighten-1"},[_vm._v("- "+_vm._s(item.amountDiscount))]),_c('br'),_vm._v(" = "+_vm._s(item.lineAmount)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.lineAmount)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","text":"","small":""},on:{"click":function($event){return _vm.editLine(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":""},on:{"click":function($event){return _vm.deleteLine(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.deleteDialog.show),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "show", $$v)},expression:"deleteDialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"title error--text"},[_vm._v("Удаление данных")]),_c('v-card-text',{staticClass:"my-2"},[_c('div',{staticClass:"text-body-2"},[_vm._v(" Вы действительно хотите произвести удаление данных? ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.deleteLineConfirmed}},[_vm._v(" Продолжить ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog.show = false}}},[_vm._v(" Отмена ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }