<template>
  <v-card>
    <v-card-title>Товары</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            color="success"
            :disabled="disabled"
            v-bind="attrs"
            class="mr-2"
            v-on="on"
            @click="addLineWithNewItem"
          >
            <v-icon>mdi-file-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Добавить товар</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            color="primary"
            :disabled="disabled"
            v-bind="attrs"
            class="mr-2"
            v-on="on"
            @click="addLine"
          >
            <v-icon>mdi-file-search-outline</v-icon>
          </v-btn>
        </template>
        <span>Добавить товар из списка</span>
      </v-tooltip>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="table.headers"
        :items="order.lines"
        :footer-props="table.footerProps"
      >
        <template v-slot:item.item.image="{ item }">
          <v-img
            v-if="item.item!=null"
            :src="item.item.image | imageUrl('thumb', true)"
            height="120"
            width="120"
            class="rounded my-1"
          ></v-img>
          <div v-else style="width:120px;height:120px" class="d-flex justify-space-around">
            <v-icon x-large>mdi-ghost-outline</v-icon>
          </div>
        </template>
        <template v-slot:item.item.code="{ item }">
          <div v-if="item.item!=null">
            <!--            <router-link :to="{ name:'order-line.edit-item',params: {itemId:item.item.id, orderId:order.id} }">-->
            {{ item.item.code }}
            <!--            </router-link>-->
          </div>
        </template>
        <template v-slot:item.item.name="{ item }">
          <div v-if="item.item!=null">
            <div>
              <router-link :to="{ name:'order-line.edit-item',params: {itemId:item.item.id, orderId:order.id} }">
                <!--              <router-link :to="{ name:'item.view',params: {id:item.item.id} }">-->
                {{ item.item.name }}
              </router-link>
            </div>
            <div class="mt-1">
              <v-chip v-for="(tag, j) in item.item.tags" :key="j" class="mr-1 mb-2" small>{{ tag.name }}</v-chip>
            </div>
            <div>
              Длина: {{ item.item.length | toint }}, Ширина: {{ item.item.width | toint }}, Высота: {{ item.item.height | toint }}
            </div>
          </div>
          <div v-else>Товар был удален</div>
        </template>
        <template v-slot:item.lineAmount="{ item }">
          <div v-if="item.amountDiscount>0">
            <span class="text-decoration-line-through">{{ item.amount }}</span>
            <br />
            <span class="red--text text--lighten-1">- {{ item.amountDiscount }}</span>
            <br />
            = {{ item.lineAmount }}
          </div>
          <div v-else>
            {{ item.lineAmount }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            fab
            text
            small
            class="mr-1"
            @click="editLine(item)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn fab text small @click="deleteLine(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="deleteDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление данных</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление данных?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteLineConfirmed">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>

import ToastService from '@/service/ToastService'
import OrderService from '@/service/OrderService'
import { mapActions } from 'vuex'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data: function () {
    return {
      table: {
        headers: [
          { text: '', value: 'item.image' },
          { text: 'Код', value: 'item.code' },
          { text: 'Название', value: 'item.name' },
          { text: 'Цена', value: 'price' },
          { text: 'Количество', value: 'quantity' },
          { text: 'Сумма', value: 'lineAmount' },
          { text: '', value: 'actions' }
        ],
        footerProps: {
          itemsPerPageOptions: [10,15,30],
          itemsPerPageText: 'Строк на странице:',
          showFirstLastPage: true
        }
      },
      itemEditDialog: {
        show: false
      },
      deleteDialog: {
        show: false,
        value: []
      }
    }
  },
  methods: {
    ...mapActions('order',['fetchItem']),
    addLine() {
      this.$router.push({ name: 'order-line.create', params: { orderId: this.order.id } })
      // ToastService.success('add new line')
    },
    editLine(line) {
      this.$router.push({ name: 'order-line.edit', params: { orderId: this.order.id, orderLineId: line.id } })
      // ToastService.info(`edit line ${line.id}`)
    },
    deleteLine(line) {
      this.deleteDialog.show = true
      this.deleteDialog.value = line
    },
    deleteLineConfirmed() {
      const line = this.deleteDialog.value
      const ids = [line.id]

      OrderService.deleteLine({ ids })
        .then(() => {
          this.fetchItem()
          ToastService.success('Данные сохранены')
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => {
          this.deleteDialog.show = false
          this.deleteDialog.value = null
        })
    },
    addLineWithNewItem() {
      this.$router.push({ name: 'order-line.create-with-new-item', params: { orderId: this.order.id } })
      // ToastService.info('Add new Item!')
    }
  }
}
</script>
